import projects from "./projects";

const pret = {
    "name": projects.pret2023,
    "description": "MAUI AI-enabled desktop application for photographers to aid high-speed image upload and meta-data tagging in the field",
    "highlights": [
        "Developed cross-platform desktop application which enabled school photographer teams to tag and upload sets of photos securely and at speed",
        "Converted existing proof-of-concept project to a production solution",
        "Rock solid platform allowed thousands of high resolution photographs to be analyzed in the field with limited connectivity",
        "Integrated Microsoft machine learning to categorise image types",
        "Project delivered on time and in budget"
    ],
    "startDate": "2023-05-01",
    "endDate": "2023-11-01",
    "roles": [
        "MAUI developer",
        "Machine learning analyst"
    ],
    "url": "https://www.pret-a-portrait.net/",
    stackCount: 4,
    "keywords": [
        "C#",
        "MAUI",
        "TensorFlow",
        "SQL",
        "XUnit",
        "ML.Net",
        "Machine Learning",
        "AWS"
    ]
};

export default pret;