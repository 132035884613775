import projects from "./projects";

const sodexo =  {
    "name": projects.sodexoEntegra,
    "description": "Uniting the billing functionality from a client's acquisition with their existing procurement platform.",
    stack: "",
    stackCount: 6,
    "highlights": [
        "Integrated extensive new functionality into an existing Azure App Service monolith and Azure Functions microservices",
        "Created a new ETL pipeline in Azure Data Factory",
        "Extended complex Entity Framework code-first model",
        "Extensive optimisation of SQL queries and stored procedures",
        "Met tight deadline ahead of launch",
    ],
    "keywords": [
        ".Net Core",
        "C#",
        "SQL",
        "Azure Data Factory ETL",
        "Entity Framework",
        "Angular",
        "Excel Integration",
        "Azure Functions",
        "Azure App Service",
        "Azure Service Bus",
        "Swagger",
        "XUnit",
        "Integration Testing",
        "End-to-end testing",
        "AngularJS",
        "Github Actions"
    ],
    "startDate": "2024-02-01",
    "endDate":"2024-09-01",
    "roles": [
        "Software Development Engineer in Test",
        "C# developer",
        "SQL developer",
        "Full stack developer"
    ],
    "url": "https://www.entegraps.com/sites/eps-us/home.html"
};

export default sodexo;