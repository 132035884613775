import { Component, OnInit } from '@angular/core';
import { StateService } from '../state.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.pug',
  styleUrls: ['./project-detail.component.styl']
})
export class ProjectDetailComponent implements OnInit {

  projectId:any;
  project:any
  constructor(private stateService:StateService, private route:ActivatedRoute) { }

  ngOnInit() {
    window.scrollTo(0,0)
  	this.route.paramMap.subscribe( params => {
  		this.projectId = params.get('id');
    });
  	this.stateService.projects$.subscribe((projects) => {
      if(projects) {
      	this.project = projects.find((x) => {return x.url == this.projectId});
      }
    });
  }
}

