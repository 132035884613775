import skills from "./skills";
import unity from "./projects/2021-04-01_unity";
import sodexo from "./projects/2024-02-01_sodexo";
import safeguardMe from "./projects/2023-04-01_safeguardMe";
import pret from "./projects/2023-05-01_pret";
import psl from "./projects/2016-02-01_psl";

const projects:IProject[] = [
    sodexo,
    safeguardMe,
    pret,
    unity,
    psl
];

const resume = {
    "orderedProjects": projects.map(x => {
        x.stack = x.keywords.splice(0, x.stackCount || 3).join(", ")
        return x
    }),
    "basics": {
        "name": "Chris Hodges",
        "label": "Software Engineer",
        "picture": "http://s.gravatar.com/avatar/1d097d52985a322a5023d9795a50e9b0?s=200",
        "email": "chris@chrishodges.me.uk",
        "phone": "07809 777184",
        "website": "https://chrishodges.me.uk",
        //"summary": "I'm a full stack developer with extensive experience. I've used a wide range of technologies working both with startups and brand leaders. I'm a strong communicator with non-technical stakeholders and prioritise expressive, comprehensible code as well as great application performance.",
        "summary": [
            "Full stack developer with 15 years experience.",
            "Extensive knowledge of .Net, Azure, Angular, React, SQL, NoSQL, Cloud Infrastructure",
            "Startup and systems architecture experience",
            "Strong communicator with technical and non-technical stakeholders",
        ],
        "location": {
            "address": "64 Warleigh Road",
            "postalCode": "BN1 4NS",
            "city": "Brighton",
            "countryCode": "UK",
            "region": "East Sussex"
        },
        "profiles": [
            {
                "network": "LinkedIn",
                "username": "chris-hodges-8287853b",
                "url": "https://www.linkedin.com/in/chris-hodges"
            },
            {
                "network": "StackOverflow",
                "username": "chris-hg",
                "url": "https://stackoverflow.com/users/1916816/chris-hg"
            },
            {
                "network": "GitHub",
                "username": "ChrisHodges",
                "url": "https://github.com/ChrisHodges"
            }
        ]
    },

    "work": [
        {
            "company": "chrishodges.me.uk",
            "position": "Full Stack Developer",
            "website": "https://chrishodges.me.uk",
            "startDate": "2015-03-01",
            "summary": "I help businesses to work smarter and be more productive, by creating intelligent, intuitive software and mobile apps built to the needs of each business I work with."
        },
        /*{
          "company": "Unity",
          "position": "Backend Lead - Learn",
          "website": "https://learn.unity.com",
          "startDate": "2021-04-01",
          "endDate": "2022-10-01",
          "summary": "Backend lead for learn.unity.com the primary learning resource for Unity, the world's most popular game engine."
        },*/
        {
            "company": "Click Databases",
            "position": "Technical Lead",
            "website": "https://clickdatabases.co.uk",
            "startDate": "2013-04-01",
            "endDate": "2015-02-01",
            "summary": "Project lead and architect for multiple high-value, data-driven projects. Responsible for day-to-day project management, code quality, hiring developers. While working for Click I helped it grow significantly, taking on new clients and embracing new technology.",
            "highlights": []
        },
        {
            "company": "Lloyds Bank",
            "position": "Web Developer",
            "startDate": "2009-08-01",
            "endDate": "2013-03-01",
            "summary": "Developed in-house .Net MVC and JavaScript applications for the bank's Fraud and Credit Control departments. Integrated data sources from Oracle, MS-SQL and Teradata SQL via stored procedures and Entity Framework",
            "highlights": [
                "Project lead on fraud prevention web application. Developed the application under-budget and it was rolled out group-wide.",
                "Developed sophisticated data dashboard for Lloyds' credit control department, using Microsoft MVC and JavaScript."
            ]
        },
        /*{
          "company": "Lloyds Bank",
          "position": "Credit Controller",
          "startDate": "2002-10-01",
          "endDate": "2009-07-01",
          "summary": "Telephone credit controller. Team leader and call quality analyst. Developed Excel-based helper software to integrate Lloyds' customer information systems."
        }*/
    ],
    "education": [
        {
            "institution": "University of York",
            "area": "Bachelor of Mathematics",
            "studyType": "Bachelor",
            "startDate": "1999-10-01",
            "endDate": "2002-06-01",
            "gpa": "4.0",
            "courses": [
                "DB1101 - Basic SQL"
            ]
        }
    ],
    skills: skills.splice(0, 10),
    "languages": [
        {
            "language": "English",
            "fluency": "Native speaker"
        }
    ],
    "references": [
        {
            "name": "Jane Doe",
            "reference": "Reference..."
        }
    ]
}

export default resume