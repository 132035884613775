import projects from "./projects";

const unity = {
    "name": projects.unityLearn,
    "description": "Rebuilding learn.unity.com, the primary learning resource for Unity, the world's most popular game engine.",
    "highlights": [
        //"Founded a new team to foster an agile culture of clean code, open communication and effective code review.",
        "Added GraphQL API layer over MongoDB datastore to allow Unity Learn resources to be consumed in a wide variety of settings.",
        "Ground-up simplification of legacy codebase resulting in more rapid development",
        "80% reduction in redundant code from the inherited codebase. 90% improvement in page load times."
    ],
    stackCount: 5,
    "keywords": [
        "Go",
        "React",
        "MongoDB",
        "Google Cloud Services",
        "GraphQL",
        "NextJS",
        "Redis",
        "Docker"
    ],
    "startDate": "2021-04-01",
    "endDate": "2022-10-01",
    "roles": [
        "Backend Lead",
        "Project manager"
    ],
    "url": "https://learn.unity.com"
};

export default unity;