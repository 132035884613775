import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.pug',
  styleUrls: ['./header.component.styl']
})
export class HeaderComponent implements OnInit {

  public showing: boolean;

  constructor() { }

  ngOnInit() {
  	this.showing = false;
  }

  public toggleMenu() {
  	this.showing = !this.showing;
  }

}
