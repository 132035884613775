import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import resume from "../../assets/resume/chrishodges-resume";

@Component({
    selector: 'app-cv',
    templateUrl: './cv.component.pug',
    styleUrls: ['./cv.component.styl']
})

export class CvComponent implements OnInit {

    r: any;

    transformResume = (r => {
        if (r.skills) {
            r.skills = r.skills.filter((s) => {
                return !s.hidden
            });
            if (r.basics.profiles && r.basics.profiles.length) {
                r.basics.profiles.forEach(p => {
                    if (p.url) {
                        p.url = p.url.replace('https://','')
                    }
                })
            }
            if (r.basics.website) {
                r.basics.website = r.basics.website.replace('https://', '')
            }
        }
        return r;
    });

    constructor(private httpClient: HttpClient) {
    }

    ngOnInit() {
        this.r = this.transformResume(resume)
    }
}
