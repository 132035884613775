import projects from "./projects";

const safeguardMe = {
    "name": projects.safeguardMe,
    "description": "Technical lead bringing innovative DBS and safeguarding startup to market",
    "highlights": [
        "Architected cross-platform child safeguarding platform from existing prototype to market-ready application",
        "Designed and built brand new Angular web frontend",
        "Ground-up rebuild of React-Native mobile application",
        "Introduced extensible no-SQL schema to replace existing SQL structure",
        "Integrated multiple 3rd-party identity and payment providers"
    ],
    "startDate": "2023-03-01",
    "endDate": "2025-01-01",
    "roles": [
        "Technical lead",
        "System architect",
        "Full stack developer",
        "Mobile developer"
    ],
    "url": "https://www.safeguard-me.co.uk/",
    stackCount:4,
    "keywords": [
        "Angular",
        "Postgres SQL",
        "MongoDB",
        "Express",
        "Laravel",
        "React Native",
        "Web Sockets",
        "Expo",
        "AWS",
        "Forge",
        "Stripe"
    ]
};

export default safeguardMe;