import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { StateService } from '../state.service';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.pug',
  styleUrls: ['./home.component.styl']
})
export class HomeComponent implements OnInit, OnDestroy {

  stateService: StateService

  faderOpacity = 0;

  getScroll = function() {
    if (window.pageYOffset != undefined) {
        return [pageXOffset, pageYOffset];
    } else {
        var sx, sy, d = document,
            r = d.documentElement,
            b = d.body;
        sx = r.scrollLeft || b.scrollLeft || 0;
        sy = r.scrollTop || b.scrollTop || 0;
        return [sx, sy];
    }
}

  constructor(@Inject(StateService) private p:StateService, private router: Router) {
  	this.stateService = p;
    window.onscroll = () => this.faderOpacity = (this.getScroll()[1] / 700)
  	router.events.subscribe((val) => {
  		if (val instanceof NavigationStart && val.url != "/") {
  			var v = this.getScroll()[1];
		  	this.stateService.homeScrollPosition = v;
  		}
  	});
  }

  ngOnInit() {
  	var v = this.stateService.homeScrollPosition;
  	setTimeout(()=>{
  		window.scrollTo(0, v);
  	}, 0);
  }

  ngOnDestroy() {
  }

}