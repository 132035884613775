import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  observer: any;
  projects: any;
  public projects$: Observable<any>;

  constructor(private httpClient: HttpClient) {
  	this.projects$ = new Observable<any>((x) => {
      if (!this.observer) {
        this.observer = x;
      }
      x.next(this.projects)
    });
  	this.httpClient.get("/assets/data/projects.json").subscribe((data) =>{
      this.observer.next(data);
      this.projects = data;
  	})
  	setTimeout(()=>{this.doAnimation = false}, 4000)
  }

  doAnimation: boolean = true;
  homeScrollPosition: number = 0;
}
