const skills = [
    {
        "name": ".Net Framework",
        "yearsExperience": "15",
        "level": "Expert"
    },
    {
        "name": ".Net Core",
        "yearsExperience": "8",
        "level": "Expert"
    },
    {
        "name": "CSharp",
        "yearsExperience": "15",
        "level": "Expert"
    },
    {
        "name": "SQL (MS-SQL/MySQL/Oracle)",
        "yearsExperience": "15",
        "level": "Expert"
    },
    {
        "name": "Entity Framework",
        "yearsExperience": "13",
        "level": "Expert"
    },
    {
        "name": "Angular",
        "yearsExperience": "9",
        "level": "Expert"
    },
    {
        "name": "AngularJS",
        "yearsExperience": "11",
        "level": "Expert"
    },
    {
        "name": "Azure",
        "yearsExperience": "8",
        "level": "Expert"
    },
    {
        "name": "JavaScript",
        "yearsExperience": "15",
        "level": "Expert"
    },
    {
        "name": "React",
        "yearsExperience": "5",
        "level": "Expert"
    },
    {
        "name": "React Native/Expo",
        "yearsExperience": "2",
        "level": "Proficient"
    },
    {
        "name": "User Experience",
        "yearsExperience": "15",
        "level": "Expert"
    },
    {
        "name": "MongoDB",
        "yearsExperience": "8",
        "level": "Proficient",
        "hidden": true
    },
    {
        "name": "PHP",
        "yearsExperience": "15",
        "level": "Proficient"
    },
    {
        "name": "WordPress",
        "yearsExperience": "12",
        "level": "Proficient"
    },
    {
        "name": "Java",
        "yearsExperience": "12",
        "level": "Intermediate"
    },
    {
        "name": "WebAPI",
        "yearsExperience": "9",
        "level": "Expert"
    },
    {
        "name": "Xamarin",
        "yearsExperience": "8",
        "level": "Proficient"
    },
    {
        "name": "Cordova/Phonegap",
        "yearsExperience": "11",
        "level": "Expert"
    },
    {
        "name": "Microsoft Graph API",
        "yearsExperience": "6",
        "level": "Proficient"
    },
    {
        "name": "Vue",
        "yearsExperience": "5",
        "level": "Intermediate",
        "hidden": true
    },
];

export default skills;