import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({ selector: '[appPreloadImage]'})
export class PreloadImageDirective {

  @Input("appPreloadImage") imageUrl : string;

  constructor( private templateRef : TemplateRef<any>,
               private viewContainer : ViewContainerRef) {
  }

  private showView() {
  	this.viewContainer.createEmbeddedView(this.templateRef);
  }

  ngOnInit() {
  	var self = this;
  	self.viewContainer.clear();
  	var tmpImg = new Image();
  	tmpImg.src = self.imageUrl;
  	tmpImg.onload = function() {
  		self.showView();
  	}
  	tmpImg.onerror = function() {
  		self.showView();
  	}
  }
}
