import projects from "./projects";

const safeguardMe = {
    "name": projects.safeguardMe,
    "description": "Technical lead bringing innovative DBS and safeguarding platform to market",
    "highlights": [
        "Took cross-platform child safeguarding platform from existing prototype to market-ready application",
        "Ground-up rebuild of React-Native mobile application",
        "Architected extensible no-SQL schema to replace existing SQL structure",
        "Integrated multiple 3rd-party identity and payment providers",
        "Delivered project on time and under budget"
    ],
    "startDate": "2023-03-01",
    "endDate": "2024-05-01",
    "roles": [
        "Technical lead",
        "System architect",
        "Full stack developer",
        "Mobile developer"
    ],
    "url": "https://www.safeguard-me.co.uk/",
    stackCount:4,
    "keywords": [
        "React Native",
        "Angular",
        "Laravel",
        "NoSQL",
        "Web Sockets",
        "Expo",
        "AWS",
        "Forge",
        "Stripe"
    ]
};

export default safeguardMe;