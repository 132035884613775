import { Component, OnInit } from '@angular/core';
import { StateService } from '../state.service';

@Component({
  selector: 'app-client-grid',
  templateUrl: './client-grid.component.pug',
  styleUrls: ['./client-grid.component.styl']
})
export class ClientGridComponent implements OnInit {

  clients: any[]
  stateService: StateService

  constructor(private s:StateService) {
    this.stateService = s;
  }

  ngOnInit() {
    this.stateService.projects$.subscribe(x => {
      this.clients = x
    })
  }

}
