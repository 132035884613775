const projects = {
    sodexoEntegra: "Entegra/Sodexo",
    safeguardMe: "Safeguard-Me",
    universalMusic: "UDiscover Music / This Day In Music",
    ageingWell: "Ageing Well",
    pret2023: "Prêt-a-Portrait",
    openEnf: "Open ENF",
    unityLearn: "Unity",
    pslEntegra:"PSL",
    theGoodExchange: "The Good Exchange",
    mesl: "Marine Entertainment Systems",
    veblen: "Veblen Wines",
    makeAWillOnline: "Make A Will Online",
    pret2015: "Pret-A-Portrait",
    checkATrade: "Check-A-Trade Emergency Callout",
};

export default projects;