import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.pug',
  styleUrls: ['./app.component.styl']
})
export class AppComponent {
  backgroundImageUrl: string = '/assets/images/background/sprite2.jpg';
  title = 'chrishodges-me-uk';
  constructor() {
  	var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  	if (viewportWidth < 600) {
  		this.backgroundImageUrl =  '/assets/images/background/sprite-thin.jpg';;
  	}
  }
}
