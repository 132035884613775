import projects from "./projects";

const psl = {
    "name": projects.pslEntegra,
    //"description": "Senior developer for PSL, a food procurement platform. The application suite monitored millions of prices and transactions per day, delivering substantial procurement savings for PSL's clients. PSL was acquired by Entegra, part of the Sodexo Group",
    "description": "Senior developer for PSL, a food procurement platform.",
    "highlights": [
        "Transitioned PSL from a SQL/VBA desktop system to a .Net/Entity Framework/Angular application suite",
        "Rapid development of multiple B2B applications",
        "Multi-million-pound savings for users.",
        "The client was acquired by Sodexo, a mult-national facilities management company."
    ],
    stackCount: 4,
    "keywords": [
        "C#",
        "SQL",
        "Angular",
        "Azure",
        "Microsoft Graph Integration",
        ".Net Framework/Core",
        "Entity Framework."
    ],
    "startDate": "2016-02-01",
    "endDate": "2020-09-01",
    "url": "https://www.entegraps.com/sites/eps-eu/home.html",
    "roles": [
        "Senior Developer"
    ]
};

export default psl;